import React from 'react';
import { connect } from 'react-redux'
import { Breadcrumb, Divider, Skeleton } from 'antd';
import { isAuth, _setAxios } from '../../lib/Helper';
import { Row, Col } from 'antd';
import AuthRedirect from '../../components/AuthRedirect'
import { DoubleRightOutlined, HomeOutlined } from '@ant-design/icons';
import Welcome from './Welcome'

import "./Dashboard.css"
import ProgressPendaftaran from './ProgressPendaftaran';
import Information from './Information';
import Persyaratan from './Persyaratan';
import ContohBerkas from './ContohBerkas';
import { isMobile } from 'react-device-detect';
import Pengumuman from './Pengumuman';

class Home extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      auth: true,
      loading: true,
      dataDiri: null,
      dataDashboard: null,

      latitude: '',
      longitude: '',
    };

    this.getMyLocation = this.getMyLocation.bind(this)

  }

  getDashboard = () => {
    _setAxios("ppdb/dashboard", "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          loading: false,
          dataDashboard: resp.data.data,
        });
      }
    })
  }

  getDataDiri = (id) => {
    _setAxios("ppdb/siswa/data-diri/" + id, "GET").then(resp => {
      if (resp.status === true) {
        this.setState({
          dataDiri: resp.data.data
        });
      }
    })
  }



  onChange(pagination, filters, sorter, extra) {
    console.log('params', pagination, filters, sorter, extra);
  }

  getMyLocation() {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
      location.getCurrentPosition((position) => {
        this.setState({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })

      }, (error) => {
        this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
      })
    }
  }


  componentDidMount() {
    this.getDashboard()
    this.getMyLocation()
    this.getDataDiri(this.props.privilege.profile_ex.id)

  }

  render() {
    const { loading } = this.state

    if (isAuth(this.props.privilege)) {
      return (
        <>
          <Breadcrumb style={{ margin: '16px 0' }} separator={<DoubleRightOutlined />}>
            <Breadcrumb.Item><HomeOutlined /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
          </Breadcrumb>
          <div style={{ minHeight: 360 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Information dataDiri={this.state.dataDiri} dataDashboard={this.state.dataDashboard} {...this.props} />
              </Col>
              <Col xs={24}>
                <Welcome loading={loading} {...this.props} {...this.state} />
              </Col>
              <Col xs={24}>
                {loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
                  <div className="dashboard-column">
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={12}>
                        <ProgressPendaftaran data={this.props.privilege} dataDashboard={this.state.dataDashboard} dataDiri={this.state.dataDiri} loading={loading} />
                      </Col>
                      <Col xs={24} md={12}>
                        {isMobile ? <Divider /> : ""}
                        <ContohBerkas loading={loading} {...this.props} />
                      </Col>
                    </Row>
                  </div>
                }
              </Col>
              <Col xs={24}>
                <Persyaratan data={this.props.privilege} loading={loading} />
              </Col>
              <Col xs={24}>
                <Pengumuman data={this.props.privilege} loading={loading} />
              </Col>
            </Row>
          </div>
        </>
      )
    } else {
      return <AuthRedirect />
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    privilege: state.privilege,
  }
}

export default connect(mapStateToProps)(Home)