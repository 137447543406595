import React from 'react';
import { Skeleton, Collapse, List } from 'antd';
const { Panel } = Collapse;

class Persyaratan extends React.Component {

  render() {
    const { loading } = this.props
    return (
      loading ? <Skeleton.Input active={true} className="skeleton-welcome" /> :
        <div className="dashboard-column">
          <div className='dashboard-column-1-ucapan'>Persyaratan Berdasarkan Jalur</div>
          <Collapse defaultActiveKey={['1']} ghost>
            <Panel header="Persyaratan Jalur Prestasi" key="1">
              <List
                size="small"
                dataSource={dataJalurPrestasi}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Panel>
            <Panel header="Persyaratan Jalur Tahfid" key="2">
              <List
                size="small"
                dataSource={dataJalurTahfid}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Panel>
            <Panel header="Persyaratan Jalur Kemitraan" key="3">
              <List
                size="small"
                dataSource={dataJalurKemitraan}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Panel>
            <Panel header="Persyaratan Jalur Reguler" key="4">
              <List
                size="small"
                dataSource={dataJalurReguler}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Panel>
          </Collapse>
        </div>
    )
  }
}

export default Persyaratan


const dataJalurPrestasi = [
  'Peraih juara dan golden ticket MAGIC MAN (dibuktikan dengan upload berkas)',
  'Peringkat 1,2,3 kelas VIII dan IX semester ganjil di SMP/MTS (dibuktikan dengan surat rekomendasi yang sudah disediakan dan bisa diunduh di web) *maka dari sisi sistem harus menyediakan dokumen template surat rekomendasi yang dapat diupload oleh calon peserta didik.',
  'Mempunyai sertifikat juara lomba akademik/non akademik minimal tingkat kabupaten (dibuktikan dengan upload berkas)',
  'Pernah menjadi ketua osis (dibuktikan dengan upload berkas)',
  'Catatan: jika siswa memilih jalur prestasi maka wajib mengupload salah 1 dari ke 4 poin diatas, apabila siswa hanya mengupload salah satu saja maka progress bisa langsung 100% agar dapat diverifikasi panitia)',
];

const dataJalurTahfid = [
  'Menyerahkan sertifikat hafalan min 5 juz dari lembaga/pembimbing (dibuktikan dengan upload berkas)',
  'Lulus tes hafalan oleh tim PPDB Man 1 gresik (proses wawancara langsung diluar sistem, sistem hanya memberikan kartu peserta wawancara)',
  'Catatan: jika siswa memilih jalur tahfid maka wajib mengupload bukti sertifikat hafalan agar progress bisa 100%, siswa yang memilih jalur tahfid dapat tetap mengupload berkas yang ada pada jalur prestasi',
  'No peserta tes wawancara: 4 digit huruf-tahun-sequence',
  'Semua berkas upload dapat berupa file JPG/JPEG/PDF maksimal 5MB',
];

const dataJalurKemitraan = [
  'Berasal dari sekolah mitra MAN 1 Gresik (sekolah asal dapat dilihat pada form pengisian registrasi/pendaftaran)',
  'Lulus tes wawancara (proses wawancara langsung diluar sistem, sistem hanya memberikan kartu peserta wawancara)',
  'No peserta tes wawancara: 4 digit huruf-tahun-sequence',
];

const dataJalurReguler = [
  'Menyerahkan fotocopy ijazah/surat keteranagn lulus dilegalisir (dibuktikan dengan upload berkas)',
  'Mengikuti tes tulis kompetensi bidang (tes langsung disekolah, sistem hanya memberikan kartu peserta)',
  'No peserta tes wawancara: 4 digit huruf-tahun-sequence',
  'Jalur UMUM: siswa masih dapat melakukan upload berkas yang ada pada syarat jalur prestasi tetapi tidak menjadi acuan dalam verifikasi)',
];