import React from 'react';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import Config from "./Config";
import { _getToken, _setAxios } from './lib/Helper';
import axios from 'axios';
import { Layout } from 'antd';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import TabBar from './components/tabbar/TabBar';
import { isMobile } from 'react-device-detect';

import './App.css';
import 'antd/dist/antd.css';

//Global
import HeaderLayout from './layouts/header/Header';
import Sidebar from './layouts/sidebar/Sidebar';
import Preload from './components/preload/Preload'
import Dashboard from './pages/dashboard/Dashboard'
import Login from './pages/login/Login';

// Error Page
import Error403 from './pages/errors/403';
import Error404 from './pages/errors/404';

//Profile
import Profile from './pages/profile/Profile';

import ProfilCalonPesertaDidik from './pages/profil-calon-peserta-didik/index';

// Peserta didik
import Registrasi from './pages/registrasi/Registrasi';

const { Content, Footer } = Layout;
export default class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      isAuth: false,
      privilege: {
        status: false,
        access: {}
      },
      menu: [],
    }
  }

  componentWillMount() {
    console.disableYellowBox = true;
    this.checkAuth()
    this.getMenu()
  }

  checkAuth() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const pathname = window.location.pathname;
      _setAxios("privilege/access", "GET").then(resp => {
        if (resp.status === true) {
          if (pathname === "/login") {
            window.location.href = Config.base_url
          } else {
            if (resp.data.data.profile_ex && resp.data.data.profile_ex.siswa_akun_ditangguhkan === 1) {
              resp.data.data.access = {
                "/": {
                  path: "/",
                  status: true,
                  action: {
                    view: true
                  }
                }
              }
              // resp.data.data.profile_ex.siswa_alasan_akun_ditangguhkan = "Yaaah kamu gak bisa login yaah kasihan"
            }

            this.setState({
              loading: false,
              isAuth: true,
              privilege: resp.data.data
            })
          }
        } else {
          this.setState({
            loading: false,
            isAuth: false,
          })
        }
      })
    }
  }

  getMenu() {
    if (!_getToken()) {
      this.setState({
        loading: false
      })
    } else {
      const headers = {
        'Accept': 'application/json',
        'Authorization': _getToken()
      };
      axios.get(Config.api + '/privilege/menu', { headers })
        .then((resp) => {
          if (this.state.privilege.profile_ex && this.state.privilege.profile_ex.siswa_akun_ditangguhkan === 1) {
            resp.data.data = [{
              icon: "icon-dashboard",
              name: "Dashboard",
              path: "/"
            }]
          }
          this.setState({ menu: resp.data.data });
        })
        .catch(function (error) {
          if (error.response) {
            // _error('topRight', 'Failed', error.response.data.message)
          }
        });
    }
  }

  render() {
    const { loading, privilege, menu } = this.state

    const globalState = {
      privilege: privilege,
      menu: menu,
      menuCollapse: false,
    }

    if (loading === true) {
      return <Preload />
    }
    //reducer
    const rootReducer = (state = globalState, action) => {
      switch (action.type) {
        case "AUTH":
          return {
            ...state,
            privilege: action.privilege,
            menu: action.menu
          }
        case "MENU":
          return {
            ...state,
            menuCollapse: action.menuCollapse,
          }
        default:
          return state
      }
    }

    //store
    const storeRedux = createStore(rootReducer);

    return (
      <Provider store={storeRedux}>
        <Router>
          {
            this.state.isAuth === true ?
              <Layout style={{ minHeight: '100vh' }}>

                <Sidebar {...this.props} />
                <Layout className="site-layout">
                  <HeaderLayout {...this.props} />
                  <TabBar {...this.props} />

                  <Content>
                    <Switch>
                      <Route exact path="/login" component={(props) => <Redirect to="/" />} />
                      <Route exact path="/" component={(props) => <Dashboard {...props} />} />
                      <Route exact path="/profile" component={(props) => <Profile {...props} />} />
                      <Route exact path="/profil-calon-peserta-didik" component={(props) => <ProfilCalonPesertaDidik {...props} />} />
                      <Route exact path="/403" component={(props) => <Error403 />} />
                      <Route component={Error404} />
                    </Switch>
                  </Content>
                  <Footer style={{ textAlign: 'center', marginBottom: isMobile ? 40 : 0 }}>Madrasah Digital MAN 1 Gresik @{new Date().getFullYear()}</Footer>
                </Layout>
              </Layout>
              :
              <>
                <Switch>
                  {/* <Route exact path="/" component={(props) => <Redirect to="/login" />} /> */}
                  <Route exact path="/registrasi" component={(props) => <Registrasi />} />
                  <Route exact path="/" component={(props) => <Login />} />
                  <Route exact path="/login" component={(props) => <Login />} />
                  <Route component={Error404} />
                </Switch>
              </>
          }

        </Router>

      </Provider>
    );
  }
}